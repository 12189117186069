import {useMutation, useQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {GET_ACCREDITATION_TYPE} from '../../graphql/accreditation/queries';
import {AccreditationType, UpdateAccreditationTypeCommandInput} from '../../graphql/__generated__/graphql';
import {UPDATE_ACCREDITATION_TYPE} from "../../graphql/accreditation/mutations";

export const useAccreditation = ({ ...props }) => {

    const { accreditationTypeId } = props;
    const [accreditationType, setAccreditationType] = useState<AccreditationType>();


    const { data, error, loading, refetch } = useQuery(GET_ACCREDITATION_TYPE, {
        variables: { id: accreditationTypeId }
    });

    useEffect(() => {
        if (!loading && !error) {
            setAccreditationType(data.accreditationType);
        }
    }, [data]);

    const [accreditationTypeUpdate] = useMutation(UPDATE_ACCREDITATION_TYPE);

    const handleChange = (name: string, value: any) => {
        setAccreditationType({
            ...accreditationType,
            [name]: name === 'price' ? parseFloat(value) : value,
        } as AccreditationType);
    };

    const handleUpdateAccreditationType = () => {
        return new Promise((resolve, reject) => {


            const updateCommand: UpdateAccreditationTypeCommandInput = {
                accreditationTypeId: accreditationType?.accreditationTypeId,
                name: accreditationType?.name || "",
                // @ts-ignore
                active: accreditationType?.active,
                description: accreditationType?.description || "",
                price: accreditationType?.price || 0,
                requirementsJson: accreditationType?.requirementsJson || ""
            }

            resolve(accreditationTypeUpdate({
                variables: {
                    accreditationType: updateCommand
                }
            }).then((response) => {
                try {
                    setAccreditationType(response.data.accreditationTypeUpdate);
                } catch (e) {
                    alert("Something went wrong. Please try again.")
                }
            }));
        });
    };

    const handleReloadAccreditation = () => {
        refetch({ id: accreditationTypeId }).then((resp) => {
            //console.log("response", resp)
        });
    }

    return {
        models: {
            accreditation: accreditationType
        },
        operations: {
            handleReloadAccreditation, handleChange, handleUpdateAccreditationType
        }
    };
};
