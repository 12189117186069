import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { AccreditationApplication, ApplicationStatus } from '../../graphql/__generated__/graphql';
import { UPDATE_ACCREDITATION_APPLICATION_STATUS } from "../../graphql/accreditation/mutations";
import { GET_ACCREDITATION_APPLICATION } from "../../graphql/accreditation/queries";

export const useAccreditationApplication = ({ ... props }) => {

  const { applicationId } = props;
  const [ application, setApplication ] = useState<AccreditationApplication>();

  const { data, error, loading, refetch } = useQuery(GET_ACCREDITATION_APPLICATION, {
    variables: { id: applicationId }
  });

  useEffect(() => {
    if (!loading && !error) {
      setApplication(data.accreditationApplication);
    }
  }, [data]);

  const [applicationStatusUpdate] = useMutation(UPDATE_ACCREDITATION_APPLICATION_STATUS);

  const handleUpdateApplicationStatus = (status: ApplicationStatus) => {
    return new Promise((resolve, reject) => {

      const updateCommand = {

      }

      resolve(applicationStatusUpdate({
        variables: {
          application: updateCommand
        }
      }).then((response) => {
        try {
          setApplication(response.data.application);
        } catch (e) {
          alert("Something went wrong!");
        }
      }))
    })
  }

  return {
    models: {
      application
    },
    operations: {
      handleUpdateApplicationStatus
    }
  }
}