import React, { useEffect } from 'react';
import { Button, Card, CardBody, Col, Form, FormControl, Row, Spinner, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useAccreditationApplications } from "./useAccreditationApplications";
import { AccreditationApplication } from "../../graphql/__generated__/graphql";


const Applications: React.FC = () => {

    const { models, operations } = useAccreditationApplications();
    const { loading, accreditationApplications, keywords, orderBy, orderByDesc } = models;

    const { setKeywords, setOrderBy, setOrderByDesc, saveStateToLocalStorage } = operations;
    // const { setExpired, setKeywords, nextPage, previousPage, handleCreateAccreditation } = operations;

    const navigate = useNavigate();

    const renderSortButton = (column: string, label: string) => {
        return (
            <th>
                {label} {orderBy === column ? (
                orderByDesc ? (
                    <Button size="sm" variant="outline-primary" onClick={() => setOrderByDesc(false)} className="ms-2 p-1 py-0">
                        <strong><i className="bi bi-arrow-up"></i></strong>
                    </Button>
                ) : (
                    <Button size="sm" variant="outline-primary" onClick={() => setOrderByDesc(true)} className="ms-2 p-1 py-0">
                        <strong><i className="bi bi-arrow-down"></i></strong>
                    </Button>
                )
            ) : (
                <Button size="sm" variant="link-primary" onClick={() => { setOrderBy(column); setOrderByDesc(false); }} className="ms-2">
                    <i style={{ opacity: 0.9 }} className="bi bi-arrow-down-up"></i>
                </Button>
            )}
            </th>
        );
    };

    const [applications, setApplications] = React.useState<AccreditationApplication[]>([]);

    useEffect(() => {
        if (!loading && accreditationApplications) setApplications(accreditationApplications);
    }, [loading, accreditationApplications]);

    return (
        <Card bg="light" border="light" className="w-100">
            <CardBody>
                <Row>
                    <Col style={{ textAlign: 'left' }}>
                        <h3>Applications</h3>
                    </Col>
                    <Col xs={"auto"}>
                        <FormControl
                            placeholder={"search..."}
                            value={keywords}
                            onChange={(e) => setKeywords(e.target.value)}
                            className="mb-2"
                        />
                    </Col>
                    <Col xs={"auto"}>
                        <Button>Approve selected</Button>
                    </Col>
                    <Col xs={"auto"}>
                        <Button>Reject selected</Button>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        {loading ? <Spinner /> : (
                            <Table>
                                <thead>
                                <tr>
                                    {renderSortButton("firstname", "First Name")}
                                    {renderSortButton("lastname", "Last Name")}
                                    {renderSortButton("updatedDate", "Updated Date")}
                                    {renderSortButton("accreditationType", "Accreditation Type")}
                                    <th />
                                    <th />
                                </tr>
                                </thead>
                                <tbody>
                                {applications.map((application) => {
                                    return (
                                      <tr key={application.accreditationApplicationId}>
                                          <td>{application.member?.firstname}</td>
                                          <td>{application.member?.lastname}</td>
                                          <td>{application.updatedDate}</td>
                                          <td>{application.accreditationType?.name}</td>
                                          <td>
                                              <Button
                                                size="sm"
                                                variant="outline-primary"
                                                onClick={() => {
                                                    saveStateToLocalStorage();
                                                    navigate("/accreditations/applications/" + application.accreditationApplicationId);
                                                }}
                                              >
                                                  Review <i className="bi bi-chevron-right"></i>
                                              </Button>
                                          </td>
                                          {/*<td>*/}
                                          {/*    <Button*/}
                                          {/*      size="sm"*/}
                                          {/*      variant="outline-primary"*/}
                                          {/*      onClick={() => {*/}
                                          {/*          saveStateToLocalStorage();*/}
                                          {/*          navigate("/accreditations/applications/" + application.accreditationApplicationId);*/}
                                          {/*      }}*/}
                                          {/*    >*/}
                                          {/*        Approve <i className="bi bi-chevron-right"></i>*/}
                                          {/*    </Button>*/}
                                          {/*</td>*/}
                                          {/*<td>*/}
                                          {/*    <Button*/}
                                          {/*      size="sm"*/}
                                          {/*      variant="outline-primary"*/}
                                          {/*      onClick={() => {*/}
                                          {/*          saveStateToLocalStorage();*/}
                                          {/*          navigate("/accreditations/applications/" + application.accreditationApplicationId);*/}
                                          {/*      }}*/}
                                          {/*    >*/}
                                          {/*        Reject <i className="bi bi-chevron-right"></i>*/}
                                          {/*    </Button>*/}
                                          {/*</td>*/}
                                          <td>
                                              <Form.Check/>
                                          </td>
                                      </tr>
                                    )
                                })}
                                </tbody>
                            </Table>
                        )}
                    </Col>
                </Row>
                {/*<div>*/}
                {/*    <Button variant="outline-primary" disabled={skip === 0 || totalPages === 0} onClick={firstPage}>*/}
                {/*        <i className="bi bi-chevron-bar-left"></i>*/}
                {/*    </Button>*/}
                {/*    <ButtonGroup className="m-2">*/}
                {/*        <Button disabled={!previousPage || skip === 0 || totalPages === 0} onClick={previousPage}>*/}
                {/*            Back*/}
                {/*        </Button>*/}
                {/*        <Button disabled={!nextPage || members?.length === 0 || totalPages === 0 || currentPage > totalPages} onClick={nextPage}>*/}
                {/*            Next*/}
                {/*        </Button>*/}
                {/*    </ButtonGroup>*/}
                {/*    <Button variant="outline-primary" disabled={totalPages === 0 || currentPage > totalPages} onClick={lastPage}>*/}
                {/*        <i className="bi bi-chevron-bar-right"></i>*/}
                {/*    </Button>*/}
                {/*    <span className="mt-3" style={{ float: 'right' }}><b>{currentPage}</b> / {totalPages + 1}</span>*/}
                {/*</div>*/}
            </CardBody>
        </Card>
    );
};

export default Applications;