import { gql } from '@apollo/client';
import {
  accreditationApplicationGQL,
  accreditationGQL,
  accreditationItemGQL,
  accreditationTypeGQL
} from '../gql/accreditationGQL';

export const CREATE_ACCREDITATION = gql`mutation accreditationCreateMutation($accreditation:CreateAccreditationCommandInput) {
    accreditationCreate(accreditation: $accreditation) {
       ${accreditationItemGQL}
    }
  }`;

export const UPDATE_ACCREDITATION = gql`
  mutation accreditationUpdateMutation($accreditation:UpdateAccreditationCommandInput) {
    accreditationUpdate(accreditation: $accreditation) {
       ${accreditationGQL}
    }
  }
`;

export const CREATE_ACCREDITATION_TYPE = gql`
  mutation accreditationTypeCreateMutation($accreditation:CreateAccreditationTypeCommandInput) {
    accreditationTypeCreate(accreditation: $accreditation) {
       ${accreditationTypeGQL}
    }
  }
`;

export const UPDATE_ACCREDITATION_TYPE = gql`
  mutation accreditationTypeUpdateMutation($accreditationType:UpdateAccreditationTypeCommandInput) {
    accreditationTypeUpdate(accreditationType: $accreditationType) {
       ${accreditationTypeGQL}
    }
  }
`;

export const DELETE_ACCREDITATION_TYPE = gql`
  mutation accreditationTypeDeleteMutation($accreditation:DeleteAccreditationTypeCommandInput) {
    accreditationTypeDelete(accreditation: $accreditation) {
       ${accreditationTypeGQL}
    }
  }
`;

export const UPDATE_ACCREDITATION_APPLICATION_STATUS = gql`
  mutation accreditationApplicationStatusUpdateMutation($accreditation:UpdateAccreditationStatusCommandInput) {
    accreditationApplicationStatusUpdate(accreditation: $accreditation) {
      ${accreditationApplicationGQL}
    }
  }
`;