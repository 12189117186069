
export let accreditationItemGQL = `
    accreditationId
    accreditationTypeId
`

export let accreditationGQL = `
    accreditationId
    accreditationTypeId
    accreditationType {
        name
    }
    accreditationStartedDate
    paymentRequestedDate
    paymentCompleteDate
    awardedDate
    rejectedDate
    startDate
    endDate
`;


export let accreditationTypeGQL = `
    accreditationTypeId
    name
    price
    description
    requirementsJson
    active
`;

export const accreditationApplicationGQL = `
  accreditationApplicationId
  member {
    firstname
    lastname
  }
  updatedDate
  accreditationType {
    name
  }
  applicationStatus
`;