import {useQuery} from '@apollo/client';
import {useEffect, useState} from 'react';
import {AccreditationApplication} from '../../graphql/__generated__/graphql';
import {GET_ACCREDITATION_APPLICATIONS} from "../../graphql/accreditation/queries";

export const useAccreditationApplications = () => {

    const getSavedState = () => {
        const savedKeywords = localStorage.getItem('keywords') || "";
        const savedShow = (localStorage.getItem('show') as "" | "active" | "expired") || "";
        const savedSkip = parseInt(localStorage.getItem('skip') || "0", 10);
        const savedOrderBy = localStorage.getItem('orderBy') || "lastname";
        const savedOrderByDesc = localStorage.getItem('orderByDesc') === "true" || false;

        return { savedKeywords, savedShow, savedSkip, savedOrderBy, savedOrderByDesc };
    };

    const { savedKeywords, savedShow, savedSkip, savedOrderBy, savedOrderByDesc } = getSavedState();

    const [accreditationApplications, setAccreditationApplications] = useState<AccreditationApplication[]>();
    const [pageSize, setPageSize] = useState(20);
    const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [skip, setSkip] = useState<number>(savedSkip);
    const [keywords, setKeywords] = useState<string>(savedKeywords);
    const [show, setShow] = useState<string>(savedShow);
    const [orderBy, setOrderBy] = useState<string>(savedOrderBy)
    const [orderByDesc, setOrderByDesc] = useState<boolean>(savedOrderByDesc);

    const [hasMounted, setHasMounted] = useState(false);

    const variables = {
        take: pageSize,
        skip,
        show,
        keywords: keywords + " " + show,
        orderBy,
        orderByDesc
    };

    const { data, error, loading } = useQuery(GET_ACCREDITATION_APPLICATIONS);

    const nextPage = () => {
        const newSkip = skip + pageSize;
        setSkip(newSkip);
    };

    const previousPage = () => {
        const newSkip = Math.max(skip - pageSize, 0);
        setSkip(newSkip);
    };



    const firstPage = () => {
        setSkip(0);
    };

    const lastPage = () => {
        const newSkip = (totalPages) * pageSize;
        setSkip(newSkip);
    };

    useEffect(() => {
        if (!loading && !error) {

            setAccreditationApplications(data?.accreditationApplicationsForEMDR as AccreditationApplication[]);
            // setCurrentPage(data?.accreditationApplications.page as number);
            // setTotalPages(data?.accreditationApplications.totalPages as number);

        }
    }, [data, loading, error]);

    useEffect(() => {
        if (hasMounted) {
            setSkip(0);  // Only reset skip when changed by the user
        } else {
            setHasMounted(true);  // Set to true after initial render
        }
    }, [keywords, show]);

    const saveStateToLocalStorage = (clear?: boolean) => {

        if (clear === true) {
            localStorage.removeItem('keywords');
            localStorage.removeItem('show');
        } else {
            localStorage.setItem('keywords', keywords);
            localStorage.setItem('show', show);
        }

        localStorage.setItem('skip', skip.toString());
        localStorage.setItem('orderBy', orderBy);
        localStorage.setItem('orderByDesc', orderByDesc.toString());


    };

    return {
        models: {
            loading, keywords, accreditationApplications, show, skip, currentPage, totalPages, orderBy, orderByDesc
        },
        operations: {
            setKeywords, setShow, nextPage, previousPage, firstPage, lastPage, saveStateToLocalStorage, setOrderBy, setOrderByDesc
        }
    };
};
