import { Button, Card, CardBody, Col, Row } from "react-bootstrap";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAccreditationApplication } from "./useAccreditationApplication";


const AccreditationApplication: React.FC = () => {

  const navigate = useNavigate();

  const { applicationId } = useParams();
  const { models, operations } = useAccreditationApplication({ applicationId });
  const { application } = models;
  const { handleUpdateApplicationStatus } = operations;

  return (
    <>
      <Card bg="light" border={"light"} className={"w-100"}>
        <CardBody>
          <Row>
            <Col xs="auto">
              <Button variant="outline-primary"
                      onClick={() => navigate("/accreditations")}
              ><i className="bi bi-chevron-left"></i></Button>
            </Col>

            {application?.member?.toString()}

          </Row>
        </CardBody>
      </Card>
    </>
  );
}

export default AccreditationApplication;